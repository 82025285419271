import * as React from 'react'
import ListItem from '@/src/components/post/ListItem'
import { data } from '@/src/types/post'

const List = ({ data }) => {
  return (
    <ul>
      {data.allMdx.nodes.map((node) => (
        <ListItem key={node.id} node={node} />
      ))}
    </ul>
  )
}

List.propTypes = { data }

export default List
