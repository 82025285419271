import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from '@/src/components/layout/Default'
import List from '@/src/components/post/List'
import { data } from '@/src/types/post'

const IndexPage = ({ data }) => {
  return (
    <DefaultLayout>
      <List data={data} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "YYYY年MMMMDo", locale: "ja")
          title
        }
        excerpt
        slug
        id
      }
    }
  }
`

IndexPage.propTypes = { data }

export default IndexPage
